const groupText = [
  { field: '誤変換', meaning: '誤変換' },
  { field: '誤用', meaning: '誤用' },
  { field: '使用注意', meaning: '使用注意' },
  { field: '用字', meaning: '用字' },
  { field: 'ら抜き', meaning: 'ら抜き' },
  { field: '不快語', meaning: '不快語' },
  { field: '機種依存または拡張文字', meaning: '機種依存または拡張文字' },
  { field: '外国地名', meaning: '外国地名' },
  { field: '固有名詞', meaning: '固有名詞' },
  { field: '人名', meaning: '人名' },
  { field: '当て字', meaning: '当て字' },
  { field: '表外漢字あり', meaning: '表外漢字あり' },
  { field: '用語言い換え', meaning: '用語言い換え' },
  { field: '二重否定', meaning: '二重否定' },
  { field: '助詞不足の可能性あり', meaning: '助詞不足の可能性あり' },
  { field: '冗長表現', meaning: '冗長表現' },
  { field: '略語', meaning: '略語' },
]

export default groupText
